body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container{
  margin-top: 30px !important;
  margin-bottom: 20px !important;
}

.card-header{
  background: #4a4a4a;
  border-radius: 1px;
  color: white !important;
}

.portfolio-summary{
  color: black;
  table-layout:fixed;
  font-weight: bold;
  margin-left: 10px;
  width:400px;
  padding: 1px;
}


.graph-header{
  color: black;
  font-size:small;
  table-layout:fixed;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 0px;
  width: 600px;
}

.is-transparent{
  color: transparent;
}

td.graph-header{
  height:100px;
  
}

#portfolio-title {
  width : "50";
}

.refreshed{
  background: transparent;
  font-size: x-small;
  margin-left: 15px;
  width: 30%;
  text-align: left;
  height: 70 px;
  padding: 150 px;
}

.news{
  color: red;
  font-size:xx-small;
  font-style: italic;
}

.card-header-title{
  color: white !important;
}

#stocks_list .card-content{
  padding-left: 0px;
  padding-right: 0px;

}
#stocks_list table{
  width: 100%;
  margin: 3px;
}

#card-cell  {
  vertical-align: middle;
  text-align: left;
  font-weight: bold;
}

 #value-cell {
  vertical-align: middle;
  text-align: right;
  font-weight: bold;
}


#redbox,#greenbox,#greybox {
  width:85px;
	height:40px;
  margin-right:100%;
  vertical-align:middle !important;
  margin-top: auto;
  padding:8px;
  border-radius: 5px;
  color:white;
  text-align: right;
  font-weight: bold;

}
#redbox {
  background:rgb(214, 13, 13);
}

#greenbox {
  background: green;
}
#greybox {
  background: grey;
}

.red{
  color: rgb(214, 13, 13);
}

.green{
  color: green;
}

.grey{
  color:gray;
}

.selected{
  background: #4dabf559;
  box-shadow: 1px 1px 5px #5555557d inset;
}

tbody tr, button{
  cursor: pointer;
  
}

td.updated_at{
  font-size: 10px;
}

.icon.market-trend{
  background: #e2e2e2;
  border-radius: 5px;
  margin-left: 3px;
}

.up-arrow{
  color: green;
}

.down-arrow{
  color: red;
}
.unsafe_script_warning{
  margin-bottom: 20px;
}
.has-background-success{
  background: green;
  color: white;
}
.modal-content{
  background: white;
  padding: 30px;
  border-radius: 5px;
  font-size: 20px;
}

